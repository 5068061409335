import React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Star from "../svg/star.svg"

const innerMenu = ({ data }) => {
  const edges = data?.menu?.edges
  return (
    <>
      <section className="mx-4">
        <header className="w-full h-36 flex items-center justify-between z-50">
          <Link to="/">
            <StaticImage
              src="../../images/Logo-new-Bordeaux.png"
              loading="eager"
              width={125}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Logo Bordeaux"
            />
          </Link>
          <div className="text-white flex items-center">
            <Link to="/menu">
              <span className="bg-white text-black uppercase border p-[6px] px-8 rounded-full cursor-pointer text-md hover:bg-[#490E13] hover:text-white">
                Menu
              </span>
            </Link>
            {/* <div className="flex flex-col pl-4">
        <span className="bg-white w-8 h-[1px] mb-2 mix-blend-difference"></span>
        <span className="bg-white w-8 h-[1px]"></span>
      </div> */}
          </div>
        </header>
        <div className="text-center w-full">
          <h1 className="uppercase font-bold text-xl text-[#490E13] m-0 pb-3">
            {edges?.[0]?.node?.category?.titolo}
          </h1>
          <h2 className="uppercase text-sm text-black pb-2">
            {/* TUTTI I BURGER SONO SERVITI CON DIPPER FRIES. */}
          </h2>
          <hr className="bg-[#490E13]" />
        </div>
      </section>
      {/* <section className="w-full ml-3">
        <ul className="flex items-start justify-between m-0">
          <li className="bg-[#490E13] text-white uppercase border p-[6px] px-6 rounded-full cursor-pointer text-sm">
            tutti
          </li>
          <li className="text-black flex items-center border-[#490E13] uppercase border ml-2 p-[6px] px-6 rounded-full cursor-pointer text-sm">
            <span className="pr-2">vegiteriano</span>
            <Vegan />
          </li>
          <li className="text-black flex items-center border-[#490E13] uppercase border ml-2 p-[6px] px-6 rounded-full cursor-pointer text-sm">
            <span className="pr-2">pollo</span>
            <Vegan />
          </li>
        </ul>
      </section> */}
      <section className="w-full bg-[#490E13] p-2 mt-2 flex items-center justify-center">
        <span className="text-center text-white font-medium text-md inline-flex items-center">
          <i className="pr-2">
            <Star />
          </i>
          OGNI AGGIUNTA O MODIFICA 0,5 €
          <i className="pl-2">
            <Star />
          </i>
        </span>
      </section>
      <section className="mx-4 mt-4">
        {(data?.menu?.edges || []).map((item, idx) => {
          return (
            <div key={idx}>
              <div className="flex items-center justify-between relative">
                <div className="flex-col">
                  <span className="font-bold text-xl block pb-1 text-[#490E13]">
                    {item?.node?.titolo}
                  </span>

                  <p className="text-sm m-0 pb-[11px] before:absolute before:w-[1px] before:h-20 before:bg-black before:right-[70px] before:bottom-0 font-sans">
                    {item?.node?.descrizione}
                  </p>
                </div>

                <span className="font-bold text-xl block text-[#490E13]">
                  € {item?.node?.prezzo}
                </span>
              </div>
              <hr className="bg-[#490E13]" />
            </div>
          )
        })}
      </section>
    </>
  )
}

export default innerMenu

export const query = graphql`
  query innerMenu($id: Int!) {
    menu: allStrapiMenu(filter: { category: { id: { eq: $id } } }) {
      edges {
        node {
          titolo
          prezzo
          descrizione
          new
          category {
            titolo
            slug
          }
        }
      }
    }
    strapiCategories {
      titolo
    }
  }
`
